import React, {Component} from 'react'
import { Link } from 'gatsby'

import Breadcrumb from "../components/breadcrumb";
import SEO from '../components/seo';
import Layout from '../layouts';

class PageNotFound extends Component {

    render (){

        return (
            <Layout>
                <SEO title="Not Found"/>
                <Breadcrumb title={'404 Page'}/>

                <section className="p-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="error-section">
                                    <h1>404</h1>
                                    <h2>page not found</h2>
                                    <Link to={'/'} className="btn btn-solid">back to home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}

export default PageNotFound